/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  SModal,
  ModalContent,
  Title,
  Message,
  EmailContainer,
  CopyButton,
  CloseIconWrapper
} from './styled';

const EmailContactModal = ({ toggle, email }) => {
  const { t } = useTranslation();

  const copyEmail = () => {
    navigator.clipboard.writeText(email);
  };

  return (
    <SModal isOpen toggle={toggle} id="email-contact-modal">
      <ModalContent id="modal-content">
        <CloseIconWrapper onClick={toggle} id="close-icon-wrapper">
          <FontAwesomeIcon 
            icon={faTimes}
            className="cross-icon"
            id="close-icon"
          />
        </CloseIconWrapper>
        <Title id="modal-title">{t('header.contact-modal.title')}</Title>
        <Message id="modal-message">{t('header.contact-modal.message')}</Message>
        <EmailContainer id="email-container">
          <span>{email}</span>
          <CopyButton onClick={copyEmail} id="copy-email-button">
            {t('common.copy')}
          </CopyButton>
        </EmailContainer>
      </ModalContent>
    </SModal>
  )
};

EmailContactModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default EmailContactModal;
