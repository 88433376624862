/** @format */

import styled from 'styled-components';
import { Modal } from '@components';
import { theme } from '@constants';

export const SModal = styled(Modal)`
  width: 350px;
`;

export const ModalContent = styled.div`
  padding: 30px;
`;

export const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: ${theme.COD_GRAY};
  text-align: center;
  margin-bottom: 15px;
`;

export const Message = styled.p`
  font-size: 14px;
  color: ${theme.COD_GRAY_LIGHT};
  text-align: center;
  margin-bottom: 20px;
`;

export const EmailContainer = styled.div`
  margin: 15px 0;
  padding: 10px;
  background: ${theme.LIGHT_GREY};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CopyButton = styled.button`
  padding: 5px 10px;
  background: ${theme.BASE_COLOR};
  color: ${theme.WHITE};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
    transform: scale(1.05); /* Slightly enlarge the button on hover */
  }

  &:active {
    background: ${theme.DARK_BASE_COLOR}; /* Change background color on click */
    transform: scale(0.95); /* Slightly shrink the button on click */
  }
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: ${theme.COD_GRAY_LIGHT};
  
  .cross-icon {
    font-size: 18px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
