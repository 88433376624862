/** @format */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Header as CHeader } from '@components';
import { setSelectedRider, setAppLanguage, trackAbEvent } from '@slices/app';
import { logout, authenticateRider, setUserLanguage, setAccountSettingsAndDynamicFields } from '@slices/auth';
import {
  userHelpers,
  socket as socketUtils,
  mixpanel as mixpanelUtils,
} from '@utils';
import { analytics, localStorageKeys } from '@constants';
import { useWorkInProgress, useSessionStorage } from '@hooks';
import { changeLanguage } from '@i18Loader';

const Header = ({ isAuthenticated }) => {
  const {
    largeScreen,
    riders,
    selectedRider,
    user,
    organizations,
    selectedOrganization,
    userAgent,
    passwordlessAuthenticationType,
  } = useSelector(state => state.app);

  const { language, workforceRiderApp } = useSelector(store => store.app);
  const loginLoading = useSelector(store => store.auth.login.loading);
  const invites = useSelector(state => state.auth.accountSettings.invites);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { WorkInProgress, openWorkInProgress } = useWorkInProgress();
  const [, , removeSearchData] = useSessionStorage('workforceTripRequestPayload');

  const ridersList = userHelpers.formatRidersList(riders);
  const isUserAgent =
    userAgent && userHelpers.checkUserAgentRole(userAgent.OrganizationUsers);

  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    if (user && !loginLoading) {
      dispatch(setAccountSettingsAndDynamicFields(user));
      const localLanguage = localStorage.getItem(localStorageKeys.USER_LANGUAGE);
      if (localLanguage && user.language !== localLanguage) {
        dispatch(setUserLanguage(localLanguage));
        changeLanguage(localLanguage);
      } else if (!localLanguage && user.language) {
        localStorage.setItem(localStorageKeys.USER_LANGUAGE, user.language);
        dispatch(setAppLanguage(user.language));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginLoading]);

  const onScheduleClicked = data => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.SCHEDULE_CLIKED,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        details: {
          ...data,
        },
      }),
    );
  };

  const onContactClicked = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.CONTACT_CLICKED,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
      }),
    );
  };
  const onSelectRider = key => {
    if (key) {
      dispatch(
        trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
          eventName: analytics.SELECT_RIDER,
          subJourneyName: 'User Agent',
          journeyName: 'Rider Experience',
          details: {
            ...key,
          },
        }),
      );
      dispatch(setSelectedRider(ridersList.indexOf(key.value)));
      dispatch(
        authenticateRider(riders[ridersList.indexOf(key.value)]?.User?.id
          || riders[ridersList.indexOf(key.value)]?.user?.id, true),
      );
      history.push('/');
    }
  };

  const onClickLogin = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.LOGIN_BUTTON,
        subJourneyName: 'Signup/Signin',
        journeyName: 'Rider Experience',
      }),
    );
    history.push('/login');
  };

  const onClickSignup = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.SIGNUP_BUTTON,
        subJourneyName: 'Signup/Signin',
        journeyName: 'Rider Experience',
      }),
    );
    history.push('/signup');
  };

  const onClickProfile = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Go to Profile',
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
      }),
    );

    history.push('/account-settings');
  };

  const onClickRides = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Go to Rides',
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
      }),
    );

    history.push('/my-rides');
  };

  const onClickGoals = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.GOALS_BUTTON,
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
      }),
    );
    openWorkInProgress(true);
  };

  const logoutUser = () => {
    removeSearchData();
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Logged Out',
        subJourneyName: 'Signup/Signin',
        journeyName: 'Rider Experience',
        elementId: 'nav-logout-link',
        isDataCy: true,
        details: {
          history,
        },
      }, () => {
        localStorage.removeItem('seenHints');
        dispatch(logout(history));
        socketUtils.destroySocket();
        mixpanelUtils.destroyMixpanelId();
      }),
    );
  };

  let orgDetails = null;
  let selectedOrgWithDetails = null;
  if (user) {
    const organization = organizations?.[selectedOrganization];

    if (organization) {
      selectedOrgWithDetails = organization;
      const { organizationId } = organization;
      orgDetails = userHelpers.getOrgDetails(organizationId);
    }
  }

  const onChangeLanguage = language => {
    dispatch(
      setUserLanguage(language),
    );
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: 'Language Changed',
        subJourneyName: 'Explore Dashboard',
        journeyName: 'Rider Experience',
        elementId: 'language-selector-dropdown',
        isDataCy: true,
        details: {
          language,
        },
      }),
    );
    changeLanguage(language);
  };

  const onOpenSendReferralCodeDialog = () => {
    dispatch(
      trackAbEvent(analytics.ABTESTING_FEATURES.ORIGINAL_FLOW, {
        eventName: analytics.INVITE_FRIENDS_BUTTON_CLICKED,
        subJourneyName: 'Invites',
        journeyName: 'Rider Experience',
      }),
    );

    history.push('/invite-friends');
  };

  const handleEmailModal = (show) => {
    setShowEmailModal(show);
  };

  return (
    <>
      <CHeader
        onClickLogin={onClickLogin}
        onClickSignup={onClickSignup}
        onClickProfile={onClickProfile}
        onClickRides={onClickRides}
        onClickGoals={onClickGoals}
        isAuthenticated={isAuthenticated}
        isPasswordlessAuthenticated={!!passwordlessAuthenticationType}
        largeScreen={largeScreen}
        logout={logoutUser}
        currentPath={location.pathname}
        riders={ridersList}
        selectedRider={selectedRider}
        onSelectRider={onSelectRider}
        orgDetails={orgDetails}
        organizations={organizations || []}
        invites={invites}
        isUserAgent={isUserAgent}
        onScheduleClicked={onScheduleClicked}
        onContactCliked={onContactClicked}
        openSendReferralCodeDialog={onOpenSendReferralCodeDialog}
        selectedOrgWithDetails={selectedOrgWithDetails}
        language={language}
        onChangeLanguage={onChangeLanguage}
        workforceRiderApp={workforceRiderApp}
        showEmailModal={showEmailModal}
        onToggleEmailModal={handleEmailModal}
      />
      <WorkInProgress />
    </>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Header;
